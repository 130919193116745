import React from "react";
import SectionFourList from "./SectionFourList";

const SectionFourAnswer = (props) => {
  const { answerParagraphs } = props;
  const answer = [];
  for (let i = 0; i < answerParagraphs.length; i++) {
    const paragraphs = answerParagraphs[i];
    answer.push(
      <div key={i}>
        {paragraphs.beforeLink ? paragraphs.beforeLink : null}
        {paragraphs.link ? (
          <>
            <a href={paragraphs.link.address} target="_blank">
              {paragraphs.link.text}
            </a>
            {paragraphs.afterLink ? paragraphs.afterLink : null}
          </>
        ) : null}
        {paragraphs.list ? <SectionFourList data={paragraphs.list} /> : null}
      </div>
    );
  }
  return answer;
};

export default SectionFourAnswer;
