import SvgSectionOneOne from "../../assets/images/vector/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_01.svg"
import SvgSectionOneTwo from "../../assets/images/vector/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_02.svg"
import SvgSectionOneThree from "../../assets/images/vector/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_03.svg"
import SvgSectionOneFour from "../../assets/images/vector/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_04.svg"
import SvgSectionTwoOne from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_01.svg"
import SvgSectionTwoTwo from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_02.svg"
import SvgSectionTwoThree from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_03.svg"
import SvgSectionTwoFour from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_04.svg"
import SvgSectionTwoFive from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_05.svg"
import SvgSectionTwoSix from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_06.svg"
import SvgSectionFourOne from "../../assets/images/vector/pages/fba-fee-calculator/SectionFour/fbaFeeCalculator-s4_01.svg"
import SvgSectionFourTwo from "../../assets/images/vector/pages/fba-fee-calculator/SectionFour/fbaFeeCalculator-s4_02.svg"
import SvgSectionFourThree from "../../assets/images/vector/pages/fba-fee-calculator/SectionFour/fbaFeeCalculator-s4_03.svg"
import SvgSectionFourFour from "../../assets/images/vector/pages/fba-fee-calculator/SectionFour/fbaFeeCalculator-s4_04.svg"
import SvgSectionFourFive from "../../assets/images/vector/pages/fba-fee-calculator/SectionFour/fbaFeeCalculator-s4_05.svg"
import SvgSectionFiveOne from "../../assets/images/vector/pages/fba-fee-calculator/SectionFive/fbaFeeCalculator-s5_01.svg"
import SvgSectionFiveTwo from "../../assets/images/vector/pages/fba-fee-calculator/SectionFive/fbaFeeCalculator-s5_02.svg"
import SvgSectionFourSix from "../../assets/images/vector/pages/fba-fee-calculator/SectionFour/fbaFeeCalculator-s4_06.svg"
import JpgSvgSectionOneOne from "../../assets/images/raster/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_yt01.jpg"
import JpgSvgSectionOneOne2x from "../../assets/images/raster/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_yt01@1_5x.jpg"
import WebpSvgSectionOneTwo from "../../assets/images/raster/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_yt01.webp"
import WebpSvgSectionOneTwo2x from "../../assets/images/raster/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_yt01@1_5x.webp"

const PG_FFC_CONTENT = {
  SecOne: {
    youtubeImg: [
      JpgSvgSectionOneOne,
      JpgSvgSectionOneOne2x,
      WebpSvgSectionOneTwo,
      WebpSvgSectionOneTwo2x,
    ],
    listImg: [
      SvgSectionOneOne,
      SvgSectionOneTwo,
      SvgSectionOneThree,
      SvgSectionOneFour,
    ],
    list: [
      {
        beforeBoldText: null,
        boldText: "Install the FBA Calculator extension",
        afterBoldText: null,
      },
      {
        beforeBoldText: null,
        boldText: "Go to the listing page of the product you’d like to sell. ",
        afterBoldText:
          "Click the FBA Calculator icon in the top right corner of your browser to open the extension.",
      },
      {
        beforeBoldText: null,
        boldText: "Enter your product cost ",
        afterBoldText:
          "(the amount you pay to purchase it), shipping costs, CPC cost,and goods and services tax percentage",
      },
      {
        beforeBoldText: null,
        boldText: "The FBA Calculator will automatically calculate ",
        afterBoldText:
          "your Amazon fees, profit per unit, net margin, ROI, and estimated monthly financial earnings",
      },
    ],
  },
  SecTwo: {
    listImg: [
      SvgSectionTwoOne,
      SvgSectionTwoTwo,
      SvgSectionTwoThree,
      SvgSectionTwoFour,
      SvgSectionTwoFive,
      SvgSectionTwoSix,
    ],
    list: [
      [
        {
          beforeBoldText: null,
          boldText:
            "The FBA program saves you time and resources by allowing you to ship your inventory to Amazon. ",
          afterBoldText:
            "The company will store your items for you in one of their warehouses, and when someone buys one of your products they’ll pick, pack, and ship it.",
        },
      ],
      [
        {
          beforeBoldText: "Of course, this service doesn’t come free. ",
          boldText:
            "You’ll be charged a monthly storage fee while your items sit on Amazon’s shelves, ",
          afterBoldText:
            "and you’ll also be charged a fulfillment fee when they ship your orders. These fees vary in price, with smaller/lighter products costing less and larger/heavier items costing more.",
        },
        {
          beforeBoldText: null,
          boldText: "This is where the FBA estimator comes in handy. ",
          afterBoldText:
            "You can see exactly what your fees will be so you can decide if the cost is worth it.",
        },
      ],
      [
        {
          beforeBoldText: null,
          boldText:
            "Overall, if you’re a new seller, or you’re a solo entrepreneur, then the FBA program is probably right for you. ",
          afterBoldText:
            "With this system, you don’t have to find space for all your inventory and you don’t have to worry about keeping up with orders. Plus, the FBA program means your products are eligible for Prime shipping, which is a great selling feature.",
        },
      ],
      [
        {
          beforeBoldText: null,
          boldText:
            "If you don’t want to pay FBA fees then your other option is Fulfilled by Merchant (FBM). ",
          afterBoldText:
            "This means you’ll have to store your inventory and fulfill your orders yourself. So while there are fewer fees it’s a lot more work.",
        },
      ],
      [
        {
          beforeBoldText: null,
          boldText:
            "Generally, this option usually works best for larger businesses that have more than one employee and an established fulfillment system in place. ",
          afterBoldText:
            "For example, if you already sell on your own website and are used to fulfilling orders yourself, FBM could be a good fit for you.",
        },
      ],
      [
        {
          beforeBoldText: "Just remember that since ",
          boldText:
            "Amazon gets a lot of traffic your orders might increase once you start selling on their marketplace, ",
          afterBoldText: "so be prepared for that. ",
        },
      ],
    ],
  },
  SecFour: {
    listImg: [
      SvgSectionFourOne,
      SvgSectionFourTwo,
      SvgSectionFourThree,
      SvgSectionFourFour,
      SvgSectionFourFive,
      SvgSectionFourSix,
    ],
    tipsList: [
      {
        beforeBoldText: null,
        boldText: "Know the Different Pricing Tiers: ",
        afterBoldText:
          "As mentioned earlier, the fees vary depending on the size of your products. Storage fees are based on cubic feet while fulfillment fees are based on weight. Be sure to familiarize yourself with the different tiers and try to choose items that fall into the cheapest tiers.",
      },
      {
        beforeBoldText: null,
        boldText: "Small and Light Program: ",
        afterBoldText:
          "If your product is 16 x 9 x 4 inches or less and under 10 oz. you’ll qualify for the small and light program. This means you’ll be eligible for extra-low fulfillment fees. If your product is under 4 oz. you’ll pay the lowest possible fulfillment fee of just $1.97.",
      },
      {
        beforeBoldText: null,
        boldText: "Seller Fulfilled Prime: ",
        afterBoldText:
          "If your product is too heavy to sell through FBA, but you still want to qualify for Prime shipping, then you can apply for Seller Fulfilled Prime. This means you’ll fulfill your orders yourself while still offering Prime shipping to customers. Amazon has high standards for this program though, so you’ll have to meet their requirements.",
      },
    ],
    questionsList: [
      {
        question: "What’s the Difference Between FBA and FBM?",
        answerParagraphs: [
          {
            beforeLink:
              "The primary difference is that with FBA, Amazon stores your inventory and ships your orders for you. With FBM, you have to do that all yourself. FBA also charges additional fees for this service, whereas with FBM you don’t have to pay any storage or fulfillment fees. Keep in mind that with both options you’ll have to pay Amazon commission for every sale you make.",
            link: null,
            afterLink: null,
            list: null,
          },
        ],
      },
      {
        question: "How Do You Use the FBA Calculator?",
        answerParagraphs: [
          {
            beforeLink:
              "Want to know how to use the FBA Calculator? It’s super easy. Once the extension is installed, go to the product page of the item you want to sell and then open the FBA Profit Calculator. Enter your product and shipping costs (you can usually get these from your supplier) to estimate your fees and profit margins.",
            link: null,
            afterLink: null,
            list: null,
          },
        ],
      },
      {
        question: "What Are the Benefits of Using FBA?",
        answerParagraphs: [
          {
            beforeLink: "There are a number of benefits, such as:",
            link: null,
            afterLink: null,
            list: null,
          },
          {
            beforeLink: null,
            link: null,
            afterLink: null,
            list: [
              {
                beforeLink: "You don’t have to store your own inventory.",
                link: null,
                afterLink: null,
                list: null,
              },
              {
                beforeLink: "You don’t have to ship orders to customers.",
                link: null,
                afterLink: null,
                list: null,
              },
              {
                beforeLink: "You qualify for Prime shipping.",
                link: null,
                afterLink: null,
                list: null,
              },
              {
                beforeLink:
                  "Because you don’t have to worry about fulfilling orders you can spend more time growing your business.",
                link: null,
                afterLink: null,
                list: null,
              },
            ],
          },
          {
            beforeLink:
              "Because of these advantages, FBA is ideal for small independent sellers.",
            link: null,
            afterLink: null,
            list: null,
          },
        ],
      },
      {
        question: "Does the FBA Calculator Work for All Marketplaces?",
        answerParagraphs: [
          {
            beforeLink:
              "Yes, you can use it in the USA, Canada, UK, Europe, Australia, and more.",
            link: null,
            afterLink: null,
            list: null,
          },
        ],
      },
      {
        question:
          "What Else Should You Check, in Addition to Fees and Sales Margins?",
        answerParagraphs: [
          {
            beforeLink: "When doing product research you should also check:",
            link: null,
            afterLink: null,
            list: null,
          },
          {
            beforeLink: null,
            link: null,
            afterLink: null,
            list: [
              {
                beforeLink: "Estimated monthly sales",
                link: null,
                afterLink: null,
                list: null,
              },
              {
                beforeLink: "How many reviews an item has",
                link: null,
                afterLink: null,
                list: null,
              },
              {
                beforeLink: "Sales and price trends",
                link: null,
                afterLink: null,
                list: null,
              },
              {
                beforeLink: "Competition levels",
                link: null,
                afterLink: null,
                list: null,
              },
            ],
          },
          {
            beforeLink:
              "Unfortunately, you can’t see this information in your Seller Central account, but it is available through the ",
            link: {
              address:
                "https://chrome.google.com/webstore/detail/amazon-product-finder-amz/njopapoodmifmcogpingplfphojnfeea",
              text: "AMZScout PRO Extension",
            },
            afterLink: ".",
            list: null,
          },
        ],
      },
      {
        question: "How Do You Use the AMZScout PRO Extension?",
        answerParagraphs: [
          {
            beforeLink:
              "Follow these steps to use the PRO Extension to check the stats listed in the last section, plus other important information:",
            link: null,
            afterLink: null,
            list: null,
          },
          {
            beforeLink: null,
            link: null,
            afterLink: null,
            list: [
              {
                beforeLink: null,
                link: {
                  address:
                    "https://chrome.google.com/webstore/detail/amazon-product-finder-amz/njopapoodmifmcogpingplfphojnfeea",
                  text: "Install the PRO Extension",
                },
                afterLink: null,
              },
              {
                beforeLink:
                  "Go to Amazon and search for a product or niche you’re interested in",
                link: null,
                afterLink: null,
              },
              {
                beforeLink:
                  "When the search results come up, click the PRO Extension icon in the top right corner of your browser to open it",
                link: null,
                afterLink: null,
              },
              {
                beforeLink:
                  "See estimated monthly sales, reviews, sales trends, niche and product scores, and more",
                link: null,
                afterLink: null,
              },
            ],
          },
          {
            beforeLink:
              "A version of the FBA Calculator is also included within the PRO Extension, making this the ultimate product research tool and a great investment",
            link: null,
            afterLink: null,
            list: null,
          },
        ],
      },
    ],
  },
  SecFive: [SvgSectionFiveOne, SvgSectionFiveTwo],
}
export default PG_FFC_CONTENT
