import React from "react";

const SectionFourList = (props) => {
  const { data } = props;
  const res = [];
  for (let i = 0; i < data.length; i++) {
    res.push(
      <li key={i}>
        {data[i].beforeLink ? data[i].beforeLink : null}
        {data[i].link ? (
          <>
            <a href={data[i].link.address} target="_blank">
              {data[i].link.text}
            </a>
            {data[i].afterLink ? data[i].afterLink : null}
          </>
        ) : null}
      </li>
    );
  }
  return <ul>{res}</ul>;
};
export default SectionFourList;
